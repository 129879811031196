<template>
  <div id="org-list">
    <div class="uk-container uk-text-left">
      <h1 class="uk-margin-remove">{{ $t('Pages.Organization.Organizations') }}</h1>
      <h2 class="uk-text-muted">{{ $t('Pages.Organization.SelectOrganization') }}</h2>

      <div uk-filter="target: .js-filter;" class="">
        <div v-if="(user && user.role === 'ADMIN') || (userRole === 'ADMIN')" class="uk-grid-small uk-grid-divider" uk-grid>
            <div>
                <ul class="uk-subnav uk-subnav-pill" uk-margin>
                    <li uk-filter-control><a href="#">{{ $t('Pages.Organization.All') }}</a></li>
                </ul>
            </div>
            <div>
                <ul class="uk-subnav uk-subnav-pill" uk-margin>
                    <li class="uk-active" uk-filter-control="filter: [data-fstatus='ACTIVE']; group: data-fstatus"><a href="#">{{ $t('Pages.Organization.Active') }}</a></li>
                    <li uk-filter-control="filter: [data-fstatus='INACTIVE']; group: data-fstatus"><a href="#">{{ $t('Pages.Organization.InactiveSuspended') }}</a></li>
                </ul>
            </div>
            <div class="uk-width-expand">
              <router-link :to="{name: 'OrganizationAdd'}">
                <button class="uk-button uk-button-primary uk-float-right new-org-btn" uk-toggle>
                  <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{ $t('Pages.AddOrganization.Title') }}
                </button>
              </router-link>

            </div>
        </div>

        <div 
          class="js-filter"
          uk-scrollspy="target: > div.org-list > .uk-card; cls: uk-animation-scale-up; delay: 10; offset-top: 2000;"
          uk-grid
        >
          <div
            v-for="(org, i) in organizations" 
            :key="i"
            class="uk-grid-item-match uk-width-1-5@m org-list uk-width-1-3@s"
            :data-status="(org.status) ? org.status : 'ACTIVE'"
            :data-fstatus="(org.status === 'SUSPENDED' || org.status === 'PENDING') ? 'INACTIVE' : 'ACTIVE'"
          >
            <org-card
              class="uk-width-1-5"
              :id="org.id"
              :name="org.name"
              :logo="(org.logo) ? org.logo.url : ''"
              :status="(org.status) ? $t('Pages.Organization.' + org.status) : $t('Pages.Organization.Active')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OrgCard from '@/components/cards/OrgCard';

export default {
  name: 'OrganizationsView',
  components: {
    OrgCard
  },
  data () {
    return {
      userRole: this.user?.role
    }
  },
  computed: {
    ...mapGetters([
      'organizations'
    ]),
    ...mapState({
      user: state => state.venom.user || {}
    })
  },
  methods: {
    async fetchOrgs () {
      const response = await this.$store.dispatch('getViewer')
      if (response?.viewer?.role) {
        this.userRole = response.viewer?.role || this.userRole
      }
      this.organizations.sort((a, b) => a.name?.toLowerCase() <= b.name?.toLowerCase() ? -1 : 1)
    }
  },
  mounted() {
    if (!this.organizations?.length) {
      this.fetchOrgs()
    }
    this.organizations.sort((a, b) => a.name?.toLowerCase() <= b.name?.toLowerCase() ? -1 : 1)
  }
}
</script>

<style scoped>

.new-org-btn {
  padding: 0 20px;
}
</style>