<template>
  <div class="uk-card uk-card-secondary uk-text-left uk-border-rounded">
    <div class="uk-cover-container uk-border-rounded uk-transition-toggle" tabindex="0">
      <router-link :to="dashboardUrl">
        <ImageOrAbbrevation :src="logo" :alt="name" width="185x" height="185px" />
      </router-link>
      <div class="uk-transition-slide-bottom uk-overlay uk-overlay-primary uk-position-bottom uk-padding-small uk-dark">
        <p class="uk-dark"><router-link :to="dashboardUrl">{{ name }}</router-link><br />
          <span class="uk-text-small">{{ status }}</span>
          <router-link v-if="user.role === 'ADMIN'" :to="{name: 'OrganizationEdit', params: { id }}" class="uk-text-small uk-float-right"><i uk-icon="icon: cog"></i></router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';

export default {
  name: 'OrgCard',
  components: {
    ImageOrAbbrevation
  },
  props: {
    name: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    logo: {
      type: String
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    dashboardUrl: function() {
      return `/organizations/${this.id}`;
    },
    ...mapState({
      user: state => state.venom.user || {}
    })
  },
  methods: {
    async showOrg() {
      await this.$store.dispatch( 'clearOrganization' );
      this.$router.push(this.dashboardUrl);
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-card {
  min-height: 185px;
  min-width: 185px;
}
span.uk-text-small {
  color: var(--white-color);
}
</style>
